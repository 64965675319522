import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mb-10" }
const _hoisted_2 = { class: "mb-4" }
const _hoisted_3 = { class: "d-flex flex-wrap py-5" }
const _hoisted_4 = {
  class: "me-5",
  style: {"width":"100%","overflow-wrap":"anywhere"}
}
const _hoisted_5 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_6 = { class: "text-gray-400 min-w-175px w-200px" }
const _hoisted_7 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_8 = { class: "text-gray-800 min-w-200px text-gray-800 text-hover-primary" }
const _hoisted_9 = { class: "text-gray-400" }
const _hoisted_10 = { class: "svg-icon svg-icon-1 svg-icon-danger" }
const _hoisted_11 = { class: "text-gray-800" }
const _hoisted_12 = { class: "text-gray-400" }
const _hoisted_13 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_14 = { class: "text-gray-800" }
const _hoisted_15 = { class: "text-gray-400" }
const _hoisted_16 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_17 = { class: "text-gray-800" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "text-gray-400" }
const _hoisted_20 = { class: "svg-icon svg-icon-1 svg-icon-success" }
const _hoisted_21 = { class: "text-gray-800" }
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "text-gray-400" }
const _hoisted_24 = { class: "svg-icon svg-icon-1 svg-icon-danger" }
const _hoisted_25 = { class: "text-gray-800" }
const _hoisted_26 = { key: 2 }
const _hoisted_27 = { class: "text-gray-400" }
const _hoisted_28 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_29 = { class: "text-gray-800" }
const _hoisted_30 = { class: "pb-5" }
const _hoisted_31 = { class: "d-flex flex-row bd-highlight mb-3 row" }
const _hoisted_32 = { class: "text-gray-400" }
const _hoisted_33 = { class: "svg-icon svg-icon-1 svg-icon-warning" }
const _hoisted_34 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.t("details")) + ":", 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("table", _hoisted_5, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen003.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("subject")) + ": ", 1)
            ]),
            _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.subject), 1)
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen017.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("module")) + ": ", 1)
            ]),
            _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.module), 1)
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_12, [
              _createElementVNode("span", _hoisted_13, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen005.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("title")) + ": ", 1)
            ]),
            _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.title), 1)
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_15, [
              _createElementVNode("span", _hoisted_16, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen014.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("publicationDate")) + ": ", 1)
            ]),
            _createElementVNode("td", _hoisted_17, _toDisplayString(_ctx.moment(_ctx.date).format("DD/MM/YYYY, h:mm a")), 1)
          ]),
          (_ctx.type == 'homework')
            ? (_openBlock(), _createElementBlock("tr", _hoisted_18, [
                _createElementVNode("td", _hoisted_19, [
                  _createElementVNode("span", _hoisted_20, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/art/art005.svg" })
                  ]),
                  _createTextVNode(_toDisplayString(_ctx.t("marked")) + ": ", 1)
                ]),
                _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.marked ? _ctx.t("yes") : _ctx.t("no")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.deadline)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_22, [
                _createElementVNode("td", _hoisted_23, [
                  _createElementVNode("span", _hoisted_24, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen008.svg" })
                  ]),
                  _createTextVNode(_toDisplayString(_ctx.t("deadline")) + ": ", 1)
                ]),
                _createElementVNode("td", _hoisted_25, _toDisplayString(_ctx.moment(_ctx.deadline).format("MMMM Do YYYY, h:mm")) + " - " + _toDisplayString(_ctx.moment(_ctx.deadline).toNow(true)), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.photos && _ctx.photos.length > 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_26, [
                _createElementVNode("td", _hoisted_27, [
                  _createElementVNode("span", _hoisted_28, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/files/fil003.svg" })
                  ]),
                  _createTextVNode(_toDisplayString(_ctx.t("photos")) + " : ", 1)
                ]),
                _createElementVNode("td", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photos, (photo) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "p-2 border bd-highlight col-4",
                          key: photo
                        }, [
                          _createVNode(_component_el_image, {
                            style: {"width":"150px","height":"150px"},
                            src: photo,
                            "preview-src-list": _ctx.photos,
                            "initial-index": 0,
                            fit: "cover"
                          }, null, 8, ["src", "preview-src-list"])
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_32, [
              _createElementVNode("span", _hoisted_33, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen004.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("description")) + ": ", 1)
            ])
          ])
        ]),
        _createElementVNode("div", {
          innerHTML: _ctx.description,
          class: "text-gray-500 pt-10 text-wrap"
        }, null, 8, _hoisted_34)
      ])
    ])
  ]))
}