
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import TeacherCard from "@/components/subject/cards/TeacherCard.vue";
import RessourceCard from "@/components/subject/cards/RessourceCard.vue";
import { useStore } from "vuex";

import DetailsSection from "@/components/subject/DetailsSection.vue";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useI18n } from "vue-i18n";

interface Exercise {
  id: string;
  subject: string;
  title: string;
  module: string;
  description: string;
  date: string;
  resources: string[];
  photos: string[];
}

interface Teacher {
  name: string;
  position: string;
  online: boolean;
  avatar: string;
}

export default defineComponent({
  name: "course-details",
  components: {
    TeacherCard,
    DetailsSection,
    RessourceCard,
  },
  setup() {
    const store = useStore();

    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const courseID = route.params.cid;

    const course = ref<Exercise>({
      id: "",
      subject: "",
      title: "",
      module: "",
      description: "",
      date: "",
      resources: [],
      photos: [],
    });
    // eslint-disable-next-line
    const teacher = ref<Teacher>({
      name: "",
      position: "",
      online: true,
      avatar: "",
    });

    if (!courseID) router.push("/404");
    ApiService.post(`/lms/seen/`, {
      query: {
        onWhat: courseID,
        parent: window.localStorage.getItem("parentId"),
        student: store.getters.currentStudent._id,
        onType: "Course",
      },
    });

    ApiService.get(`/lms/student/course/${courseID}`)
      .then(({ data }) => {
        course.value = {
          subject: data.subject.name,
          id: data._id,
          title: data.title,
          module: data.module,
          description: data.desc,
          date: data.createdAt,
          resources: data.resources.filter(
            (f) => !["png", "jpg"].includes(f.split(".")[1])
          ),
          photos: data.resources
            .filter((f) => ["png", "jpg"].includes(f.split(".")[1]))
            .map(
              (p: string) => store.getters.serverConfigUrl.base_url + "/" + p
            ),
        };
        teacher.value = {
          name: `${data.teacherName.firstName} ${data.teacherName.lastName}`,
          position: data.teacher.speciality,
          online: true,
          avatar:
            store.getters.serverConfigUrl.base_url +
            "/" +
            data.teacherName.photo,
        };
        setCurrentPageBreadcrumbs(course.value.title, [t("courses")]);
      })

      .catch((e) => {
        console.log(e);
        router.push("/404");
      });
    return { course, teacher, courseID, moment };
  },
});
